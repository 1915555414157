import React from "react"
import Layout from "../components/layout"
import BgAbout from "../components/bgAbout"

const P = ({ text }) => (
  <p
    className={`${Style.TextStyle.all} ${Style.TextStyle.small} ${Style.TextStyle.large}`}
  >
    {text}
  </p>
)

const Style = {
  Container: {
    all: " relative container",
    small: "px-5 pt-40 sm:pt-24 sm:px-6 md:px-6",
    large: "lg:pt-40 lg:px-24 xl:px-20",
  },
  TitleCont: {
    all: "w-full",
  },
  Title: {
    all:
      "frk-bold mb-1 text-primary text-2xl sm:text-3xl lg:mb-3 lg:text-5xl xl:text-6xl ",
  },
  TextStyle: {
    all: "frk-regular text-secondary text-lg lg:mb-6",
    small: "mb-5 sm:mb-6",
    large: "",
  },
  BodyCont: {
    all:"mt-8 mb-24 sm:mt-16 sm:mb-10  lg:mt-24 lg:mb-32"
  }
}

const PrivacyPolicy = () => {
  return (
    <Layout title="Privacy Policy" link="/privacy-policy">
      <BgAbout bAquaNotShow={true}/>
      <main>
        <div
          className={`${Style.Container.all} ${Style.Container.small} ${Style.Container.large}`}
        >
          <div className={`${Style.TitleCont.all}`}>
            <h1 className={`${Style.Title.all}`}>Privacy Policy </h1>
            <p
              className={`${Style.TextStyle.all} ${Style.TextStyle.small} ${Style.TextStyle.large}`}
            >
              Last update was on 03/24/2020
            </p>
          </div>
          <section className={Style.BodyCont.all}>
            <P
              text="This Privacy Policy applies to all HelloBuild, LLC (or “Build”)
              websites. The main Build website is hellobuild.co."
            />
            <P
              text="Build may collect information about the visitors of its websites, 
              but never intends to collect Personal Identifiable Information. Build aims to collect 
              only information freely provided by the visitors. This is usually restricted to forms filled by visitors and is normally restricted to email and name. "
            />
            <P text="All other data collected is automatically generated server logs from our web servers." />
            <P text="Currently, Build does not use any third party analytics tool, and does not set cookies on the visitor’s computer." />
            <P
              text="Build will always strive to use standard and current encrypting techniques to mask the user’s data collected. However, this might not prevent a hack, 
              or guarantee that user data will not be made public by bad actors. Visitors of all Build websites accept this risk."
            />
            <P
              text="Users will always have the right to delete information they have granted Build in the past. Build will make reasonable 
              efforts to wipe all data associated with a visitor when requested, ASAP. Requests to remove personal data previously granted 
              to Build can be directed to privacy@hellobuild.co."
            />
            <P text="Build, under its current ownership and management, commits to not sell any collected user data to third parties." />
            <P text="Build reserves the right to modify this Privacy Policy at any time. Build will make all reasonable efforts to inform the website visitors when changes are made to this policy.  " />
            <P
              text="In the event of Build being acquired (change of ownership) new owners will inherit and take responsibility for all the user’s data collected. A new owner might also make changes 
                to this Privacy Policy. Build will make all reasonable efforts to inform the visitors before any eventual acquisition is finalized."
            />
            <P text="This Privacy Policy is in effect when and in the format posted publicly on the main Build website (hellobuild.co/privacy)" />
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default PrivacyPolicy
