import React from "react"
import ImageContainer from "../components/imageContainer"
import OvalMobile from "../images/ovalMobile.png"
import SmallOval from "../images/smallOval.png"
import OvalBlue from "../images/ovalBlue.png"
import Shape1 from "../images/shape1.png"

const Style = {
  MainDiv: "w-full  absolute",
  Shape1Div: "w-24 absolute  hidden lg:mt-16",
  SectionCompleteShapes:"flex justify-end w-full absolute",
  SmallOvalDiv:"hidden sm:block sm:w-12 mt-16 lg:w-16 lg:mt-24  px-2 w-full",
  OvalMobileDiv: "w-48 pl-4 sm:pl-0 sm:w-48  lg:w-84",
  BgAquaShowTrueDiv: "w-full hidden sm:block sm:flex sm:mt-32 sm:justify-end  lg:mt-40 lg:mt-56",
  OvalBlueDiv: "w-full object-cover  object-left-bottom  sm:w-48 sm:mr-16 lg:mr-32 lg:w-72"
}

const BgAbout = ({ bLogoShow, bAquaNotShow }) => {
  let bLogoShowLet = bLogoShow && bLogoShow.length > 0 ? bLogoShow : "hidden"
  let bAquaNotShowLet = bAquaNotShow ? "hidden" : "block"
  return (
    <div className={Style.MainDiv}>
      <ImageContainer
        container={`${Style.Shape1Div} lg:${bLogoShowLet} `}
        src={Shape1}
        alt="shape1"
      />
      <div className={Style.SectionCompleteShapes}>
        <ImageContainer
          container={Style.SmallOvalDiv}
          src={SmallOval}
          alt="smallOval"
        />
        <ImageContainer
          container={Style.OvalMobileDiv}
          src={OvalMobile}
          alt="OvalGreen"
        />
      </div>
      <div
        className={`${Style.BgAquaShowTrueDiv} sm:${bAquaNotShowLet}`}
      >
        <ImageContainer
          container={Style.OvalBlueDiv} //"hidden sm:block sm:mt-4  sm:flex sm:justify-end md:mt-12 lg:justify-end lg:mt-48"
          src={OvalBlue}
          alt="Oblue"
          //className=""
        />
      </div>
    </div>
  )
}

export default BgAbout
